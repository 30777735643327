/* eslint-disable no-unused-vars */
// https://github.com/bkkr-team/bkkr-choices

import Choices from 'choices.js';

const options = {
  placeholder: false,
  searchEnabled: false,
  shouldSort: false,
  noResultsText: 'No results found',
  noChoicesText: 'No choices to choose from',
  itemSelectText: '',
};

export default function selectInit() {
  const selects = Array.from(document.querySelectorAll('.js-select'));
  selects.forEach((select) => {
    const singleSelect = new Choices(select, options);

    singleSelect.passedElement.element.addEventListener(
      'change',
      (event) => {
        if (event.detail.value !== '') {
          const formItem = event.target.parentNode.parentNode.parentNode.parentNode;
          if (formItem.classList.contains('form-item--select') ** formItem.classList.contains('-has-error')) {
            formItem.classList.remove('-has-error');
          }

          if (event.target.classList.contains('error')) {
            event.target.classList.remove('error');
            event.target.removeAttribute('aria-describedby');
            event.target.removeAttribute('aria-invalid');
          }

          const errorElement = formItem.nextElementSibling;
          if (errorElement.classList.contains('error')) {
            errorElement.innerHTML = '';
          }
        }
      },
      false,
    );
  });
}

export default function checkboxRadioInit() {
  const groups = Array.from(document.querySelectorAll('.-in-group'));

  groups.forEach((group) => {
    group.addEventListener('change', (e) => {
      const formGroup = e.target.parentNode.parentNode.parentNode;
      const formItems = Array.from(formGroup.querySelectorAll('.form-item'));
      formItems.forEach((formItem) => {
        formItem.classList.remove('-has-error');
        const input = formItem.querySelector('input');
        input.classList.remove('error');
        input.removeAttribute('aria-describedby');
        input.removeAttribute('aria-invalid');
      });
    });
  });
}

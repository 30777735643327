/* global gtag */
// NOTE: see more for config options at https://github.com/cferdinandi/bouncer

import Bouncer from 'formbouncerjs';
import messagesValidation from './messages/validation';
import messagesSubmit from './messages/submit';

// Set language
const { lang } = document.documentElement;

// Prepopulate submit messages
const messageSuccess = document.querySelector('.js-form-success');
const messageErrorGeneral = document.querySelector('.js-form-error-general');
const submit = document.querySelector('.js-form-submit');
const form = document.querySelector('.js-form');

const toggleSubmitMessages = (elementToShow) => {
  // Hide/reset all messages
  messageSuccess.classList.add('hidden');
  messageErrorGeneral.classList.add('hidden');

  if (elementToShow === 'success') {
    // form.classList.add('hidden');
    form.reset();
    submit.disabled = false;
    messageSuccess.classList.remove('hidden');
    submit.classList.remove('-loading');
  } else if (elementToShow === 'errorGeneral') {
    messageErrorGeneral.classList.remove('hidden');
  }
};

const setSubmitting = (condition) => {
  if (condition) {
    // Disable submit button, show loading
    submit.disabled = true;
    submit.classList.add('-loading');
  } else if (!condition) {
    // Enable submit button, hide loading
    submit.disabled = false;
    submit.classList.remove('-loading');
  }
};

export default function formInit() {
  if (form) {
    messageSuccess.innerHTML = messagesSubmit[lang].success;
    messageErrorGeneral.innerHTML = messagesSubmit[lang].errorGeneral;

    // eslint-disable-next-line no-unused-vars
    const bouncer = new Bouncer('.js-form', {
      disableSubmit: true,
      fieldClass: 'error', // NOTE: plugin doesn't let us use empty value, so this is used just as a placeholder and is not anyhow connected with our input macro
      errorClass: 'form-item__error',
      messages: {
        ...messagesValidation[lang],
      },
    });

    // Listener for form valid
    document.addEventListener(
      'bouncerFormValid',
      () => {
        // Posting data
        setSubmitting(true);
        const body = new FormData(form);
        const formName = form.getAttribute('name');
        body.append('form-name', formName);

        fetch('', {
          method: 'POST',
          body,
        })
          .then(() => {
            if (typeof window.gtag === 'function') {
              // If cookies were accepted
              gtag('event', 'generate_lead', {
                event_label: formName,
                event_category: 'engagement',
              });
            }
            toggleSubmitMessages('success');
            setSubmitting(false);
          })
          .catch((e) => {
            toggleSubmitMessages('errorGeneral');
            setSubmitting(false);
            console.warn(e); // eslint-disable-line no-console
          });
      },
      false,
    );

    // Listener for show error
    document.addEventListener(
      'bouncerShowError',
      (event) => {
        // Adding error class name to form item
        event.target.closest('.form-item').classList.add('-has-error');
      },
      false,
    );

    // Listener for remove error
    document.addEventListener(
      'bouncerRemoveError',
      (event) => {
        // Removing error class name from form item
        event.target.closest('.form-item').classList.remove('-has-error');
      },
      false,
    );
  }
}
